import React from 'react'
import {graphql} from 'gatsby'
import {Container} from 'theme-ui'
import Seo from '@solid-ui-components/Seo'
import Layout from '@solid-ui-layout/Layout'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block02'
import Companies from '@solid-ui-blocks/Companies/Block01'
import PowerOfProcesses from '@solid-ui-blocks/FeaturesWithPhoto/Block01'
import Stats from '@solid-ui-blocks/Stats/Block01'
import Testimonials from '@solid-ui-blocks/Testimonials/Block04'
import Blog from '@solid-ui-blocks/Blog/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import {normalizeBlockContentNodes} from '@blocks-helpers'
import theme from './_theme'
import styles from './_styles'
import Features from "@solid-ui-blocks/Features/Block06/Block06";
import WithRecentPosts from "@elegantstack/solid-ui-blocks/src/WithRecentPosts";
import Contact from '@solid-ui-blocks/Hero/Block03'

const IndexPage = props => {
    const {allBlockContent} = props.data
    const content = normalizeBlockContentNodes(allBlockContent?.nodes)

    return (
        <Layout theme={theme} {...props}>
            <Seo dontSuffixTitle title='Miragon'/>
            {/* Modals */}
            <ModalWithTabs content={content['contact']}/>
            {/* Blocks */}
            <Header content={content['header-light']}/>
            <Container variant='full' sx={styles.heroContainer}>
                <Hero content={content['hero']}/>
                <Divider space='5'/>
                <Container>
                    <Container bg="betaDark" variant='cards.paper-lg'>
                        <Companies content={content['companies']}/>
                    </Container>
                </Container>
                <Divider space='6'/>
            </Container>
            <Divider space='3'/>
            <Features content={content['features']}/>
            <Divider space='5'/>
            <PowerOfProcesses content={content['feature-one']}/>
            <Divider space='5'/>
            <Container>
                <Container variant='cards.paper-lg'>
                    <Companies content={content['partner']}/>
                </Container>
            </Container>
            <Divider space='5'/>
            <Container sx={styles.testimonialsContainer}>
                <Testimonials content={content['testimonials']}/>
            </Container>
            <Divider space='5'/>
            <Stats content={content['stats']}/>
            <Divider space='5'/>
            <WithRecentPosts>
                <Blog content={content['latest-blogs']}/>
            </WithRecentPosts>
            <Divider space='5'/>
            <Container variant='wide' sx={styles.ctaContainer}>
                <Contact content={content['cta']}/>
            </Container>
            <Divider space='5'/>
            <Footer content={content['footer']}/>
        </Layout>
    )
}

export const query = graphql`
    query homepageSiteBlockContent {
        allBlockContent(
            filter: { page: { in: ["site", "shared"] } }
        ) {
            nodes {
                ...BlockContent
            }
        }
    }
`
export default IndexPage
