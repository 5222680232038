import React, {useState} from 'react'
import {Box, Container, css, Flex} from 'theme-ui'
import {FaDotCircle} from 'react-icons/fa'
import Reveal from '@elegantstack/solid-ui-components/src/Reveal'
import Divider from '@elegantstack/solid-ui-components/src/Divider'
import ListItem from '@elegantstack/solid-ui-components/src/ListItem'
import ContentText from '@elegantstack/solid-ui-components/src/ContentText'
import ContentImages from '@elegantstack/solid-ui-components/src/ContentImages'
import WithDefaultContent from '@elegantstack/solid-ui-blocks/src/WithDefaultContent'
import ContentButtons from "@elegantstack/solid-ui-components/src/ContentButtons";

const styles = {
    container: {
        background: `linear-gradient(
      0,
      #f6f7fb 0%,
      #e0e7ef 100%
    )`,
        py: [5, 5, 6]
    },
    controlWrapper: {
        position: `absolute`,
        bottom: 0,
        left: 0
    },
    control: {
        color: `omega`,
        cursor: `pointer`,
        transition: `all 0.2s ease-in`,
        mr: 2,
        ':hover': {
            color: `omegaDark`
        },
        '&.active': {
            color: `alpha`
        }
    },
    avatar: {
        borderTopColor: `omegaLighter`,
        borderTopStyle: `solid`,
        bg: `omegaLighter`,
        borderRadius: `lg`,
        mx: `auto`,
        mb: [4, 0],
        mt: [5, 0],
        img: {
            borderRadius: `lg`
        }
    }
}

const TestimonialsBlock04 = ({content: {text, collection, buttons}, reverse}) => {
    const [state, setState] = useState(0)

    return (
        <Container>
            <ContentText content={text}/>
            <Divider/>
            <Container>
                {collection.map(
                    ({text, avatar, icon, buttons}, index) =>
                        index === state && (
                            <Flex
                                sx={{
                                    alignItems: [null, `center`],
                                    flexDirection: [
                                        reverse ? `column-reverse` : `column`,

                                        reverse ? `row-reverse` : `row`
                                    ]
                                }}
                            >
                                <Box
                                    sx={{
                                        flexBasis: [null, `2/5`],
                                        [reverse ? 'ml' : 'mr']: [null, 5],
                                        position: `relative`,
                                        textAlign: `center`
                                    }}
                                >
                                    <ContentImages
                                        content={{images: [avatar]}}
                                        sx={styles.avatar}
                                        imageEffect='fadeIn'
                                    />
                                    {buttons && (
                                        <Box sx={{textAlign: `center`, marginBottom: '5px'}}>
                                            <Divider/>
                                            <ContentButtons content={buttons}/>
                                        </Box>
                                    )}
                                </Box>
                                <Box sx={{flexBasis: `3/5`, textAlign: [`center`, `left`]}}>
                                    <Flex
                                        sx={{
                                            flexDirection: `column`,
                                            minHeight: [`none`, 420],
                                            position: `relative`
                                        }}
                                    >
                                        <Reveal effect='fadeInRight'>
                                            <ContentText content={text?.slice(0, 3)}/>
                                            <Box sx={{display: `inline-block`, textAlign: `left`}}>
                                                <ListItem
                                                    key={`item-${index}`}
                                                    text={text?.slice(3, 5)}
                                                    icon={icon}
                                                    iconProps={{mr: 2, size: 'md', round: true}}
                                                    compact
                                                    center
                                                />
                                            </Box>
                                        </Reveal>
                                    </Flex>
                                </Box>
                            </Flex>
                        )
                )}
                <Divider space={2}/>
                <Box sx={{textAlign: `center`}}>
                    {Array.from({length: collection.length}, (_, i) => (
                        <FaDotCircle
                            size={24}
                            css={css(styles.control)}
                            className={i === state ? 'active' : undefined}
                            onClick={() => setState(i)}
                        />
                    ))}
                </Box>
                {buttons && (
                    <Box sx={{textAlign: `center`}}>
                        <Divider/>
                        <ContentButtons content={buttons}/>
                    </Box>
                )}
            </Container>
        </Container>
    )
}

export default WithDefaultContent(TestimonialsBlock04)
